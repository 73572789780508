<template>
  <div class="row">
    <div class="col-12">
      <ibox :title="'Grupy kodów rabatowych'">
        <template slot="content" slot-scope="{ setLoading }">
          <DiscountCodeGroup
            :url="url"
            :url_discount_code_groups="url_discount_code_groups"
            @loading="setLoading"
          />
        </template>
      </ibox>
    </div>
  </div>
</template>

<script>
import Ibox from "@/components/base/Ibox.vue";
import DiscountCodeGroup from "@/components/Statistics/DiscountCodeGroup";

export default {
  name: "DiscountCodeGroupStatistics",
  components: {
    Ibox,
    DiscountCodeGroup
  },
  data() {
    return {
      url: `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/statistics/discount-codes-groups`,
      url_discount_code_groups: `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/lists/discount-code-groups`
    };
  }
};
</script>
