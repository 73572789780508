<template>
  <div>
    <form autocomplete="off" @submit.prevent="handleSubmit">
      <input
        autocomplete="off"
        name="hidden"
        type="text"
        style="display: none"
      />
      <div
        class="row"
        :class="{ 'has-error': errors.has('radioClubOrgroupId') }"
      >
        <div class="form-group col-sm-4">
          <label> Grupa kodów rabatowych </label>
          <v-select
            name="id"
            v-model="dates.id"
            v-validate="'required'"
            :label="labelSelectGroup"
            :filter-by="this.filterSelect"
            @search="getDiscountCodeGroupList"
            :options="discountCodeGroupListSelect"
            :reduce="
              discountCodeGroupListSelect => discountCodeGroupListSelect.id
            "
            placeholder="Zacznij pisać żeby wyszukać"
          >
            <template #option="discountCodeGroupListSelect">
              <div class="product-label">
                {{ discountCodeGroupListSelect.name }}
              </div>
              <br />
              <cite>Id: {{ discountCodeGroupListSelect.id }}</cite>
              <hr />
            </template>
          </v-select>
          <small v-show="errors.has('id')" class="text-danger">
            {{ $t("field_required") }}
          </small>
        </div>
        <div class="col-8">
          <div class="row">
            <div
              class="form-group col-sm-4"
              :class="{ 'has-error': errors.has('dateStart') }"
            >
              <label>
                {{ $t("start_date") }}
              </label>
              <date-picker
                class="NeoDataPicker"
                v-model="dates.dateStart"
                :config="optionsDate"
                name="dateStart"
                autocomplete="off"
                v-validate="'required'"
              />
              <small v-show="errors.has('dateStart')" class="text-danger">
                {{ $t("field_required") }}
              </small>
            </div>
            <div
              class="form-group col-sm-4"
              :class="{ 'has-error': errors.has('dateEnd') }"
            >
              <label>
                {{ $t("end_date") }}
              </label>
              <date-picker
                class="NeoDataPicker"
                v-model="dates.dateEnd"
                :config="optionsDate"
                name="dateEnd"
                autocomplete="off"
                v-validate="'required'"
              />
              <small v-show="errors.has('dateEnd')" class="text-danger">
                {{ $t("field_required") }}
              </small>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="btn btn-primary ml-1" @click="set30">
                {{ $t("last_month") }}
              </div>
              <div class="btn btn-primary ml-1" @click="set90">
                {{ $t("last_3_months") }}
              </div>
              <div class="btn btn-primary ml-1" @click="set180">
                {{ $t("last_6_months") }}
              </div>
              <div class="btn btn-primary ml-1" @click="set365">
                {{ $t("last_12_months") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hr-line-dashed"></div>
      <div class="form-group row">
        <div class="col-sm-4 col-sm-offset-2">
          <button class="btn btn-primary btn-sm" type="submit">
            Pobierz dane
          </button>
        </div>
      </div>
      <div class="hr-line-dashed"></div>
      <div class="row">
        <div class="col-12 col-md-4 mt-2">
          <span v-if="statistics && statistics.discountCodeStatistics">
            <h2>Ilość kodów z wybranej grupy</h2>
            <div
              v-for="(index, i) in statistics.discountCodeStatistics"
              :key="i"
            >
              <ul>
                <li>
                  Ilość kodów: <strong>{{ index["quantity"] }}</strong>
                </li>
                <li>
                  Ilość wykorzystanych kodów:
                  <strong>{{ index["quantity_used"] }}</strong>
                </li>
                <li>
                  Ilość niewykorzystanych kodów:
                  <strong>{{ index["quantity_left"] }}</strong>
                </li>
              </ul>
            </div>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 mt-2">
          <span v-if="statistics && statistics.discountCodeOrdersStatistics">
            <h2>Zamówienia z użyciem kodów z wybranej grupy</h2>
            <div
              v-for="(index, i) in statistics.discountCodeOrdersStatistics"
              :key="i"
            >
              <ul>
                <li>
                  Ilość zamówień: <strong>{{ index["counted_orders"] }}</strong>
                </li>
                <li>
                  Suma netto zamówień:
                  <strong
                    >{{ index["sum_total_amount_net"]
                    }}{{ index["currency"] }}</strong
                  >
                </li>
                <li>
                  Suma brutto zamówień:
                  <strong
                    >{{ index["sum_total_amount_br"]
                    }}{{ index["currency"] }}</strong
                  >
                </li>
                <li>
                  Suma netto zamówień z darmową dostawą:
                  <strong
                    >{{ index["order_amount_free_delivery_net"]
                    }}{{ index["currency"] }}</strong
                  >
                </li>
                <li>
                  Suma brutto zamówień z darmową dostawą:
                  <strong
                    >{{ index["order_amount_free_delivery_br"]
                    }}{{ index["currency"] }}</strong
                  >
                </li>
              </ul>
            </div>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4 mt-4">
          <span
            v-if="statistics && statistics.discountCodeOrderStatusStatistics"
          >
            <h2>Ilość zamówień grupy kodów (podział na status zamówienia)</h2>
            <p
              v-for="(status,
              i) in statistics.discountCodeOrderStatusStatistics"
              :key="i"
            >
              {{ status["status"] }}:
              <strong>{{ status["counted_orders"] }}</strong>
              (
              {{
                Math.round(
                  ((status["counted_orders"] * 100) / countedOrders) * 100
                ) / 100
              }}% )<br />
            </p>
          </span>
        </div>
        <div class="col-12 col-md-3 mt-3">
          <canvas id="discount-code-group-statistics-orders"></canvas>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4 mt-5">
          <span
            v-if="statistics && statistics.discountCodeOrderStatusStatistics"
          >
            <h2>Wartość zamówień grupy kodów (podział na status zamówienia)</h2>
            <p
              v-for="(status,
              i) in statistics.discountCodeOrderStatusStatistics"
              :key="i"
            >
              {{ status["status"] }}:
              <strong
                >{{ status["total_amount"] }}{{ status["currency"] }}</strong
              >
              (
              {{
                Math.round(
                  ((status["total_amount"] * 100) / countedAmount) * 100
                ) / 100
              }}% )<br />
            </p>
          </span>
        </div>
        <div class="col-12 col-md-3 mt-5">
          <canvas id="discount-code-group-statistics-amount"></canvas>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "DiscountCodeGroupStatistics",
  data() {
    return {
      dates: {
        dateStart: null,
        dateEnd: null,
        id: null
      },
      statistics: {},
      discountCodeGroupListSelect: [],
      countedOrders: 0,
      countedAmount: 0,
      labelSelectGroup: "name",
      discountCodeStatistics: null,
      discountCodeOrdersStatistics: null,
      discountCodeOrderStatusStatistics: null,
      chartOrders: null,
      chartAmount: null
    };
  },
  props: {
    url: {
      type: String,
      required: true
    },
    url_discount_code_groups: {
      type: String,
      required: true
    }
  },
  watch: {
    "dates.dateStart": function(newDateStart, oldDateStart) {
      if (newDateStart !== oldDateStart) {
        this.clearCharts();
      }
    },
    "dates.dateEnd": function(newDateEnd, oldDateEnd) {
      if (newDateEnd !== oldDateEnd) {
        this.clearCharts();
      }
    },
    "dates.id": function(newId, oldId) {
      if (newId !== oldId) {
        this.clearStats();
        this.clearCharts();
      }
    }
  },
  created() {
    this.dates.dateEnd = this.$moment().format("YYYY-MM-DD");
    this.dates.dateStart = this.$moment(this.dates.dateEnd)
      .subtract(1, "months")
      .format("YYYY-MM-DD");
    this.$emit("loading", false);
  },
  methods: {
    set30() {
      this.dates.dateEnd = this.$moment().format("YYYY-MM-DD");
      this.dates.dateStart = this.$moment(this.dates.dateEnd)
        .subtract(1, "months")
        .format("YYYY-MM-DD");
    },
    set90() {
      this.dates.dateEnd = this.$moment().format("YYYY-MM-DD");
      this.dates.dateStart = this.$moment(this.dates.dateEnd)
        .subtract(3, "months")
        .format("YYYY-MM-DD");
    },
    set180() {
      this.dates.dateEnd = this.$moment().format("YYYY-MM-DD");
      this.dates.dateStart = this.$moment(this.dates.dateEnd)
        .subtract(6, "months")
        .format("YYYY-MM-DD");
    },
    set365() {
      this.dates.dateEnd = this.$moment().format("YYYY-MM-DD");
      this.dates.dateStart = this.$moment(this.dates.dateEnd)
        .subtract(12, "months")
        .format("YYYY-MM-DD");
    },
    filterSelect: (option, id, search) => {
      const temp = search.toLowerCase();
      if (!option.group) {
        return (
          option.name.toLowerCase().indexOf(temp) > -1 ||
          option.id.toString().indexOf(temp) > -1
        );
      }
      return (
        option.name.toLowerCase().indexOf(temp) > -1 ||
        option.group.toLowerCase().indexOf(temp) > -1
      );
    },
    async getDiscountCodeGroupList(value) {
      if (value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.$http
            .get(this.url_discount_code_groups, { params: { search: value } })
            .then(resp => {
              this.discountCodeGroupListSelect =
                resp.data.discountCodeGroupList;
              console.log("test", this.discountCodeGroupListSelect);
            })
            .catch(err => {
              this.$toastr.error(err);
            });
        }, 500);
      }
    },
    clearStats() {
      this.statistics !== null
        ? (this.statistics = {})
        : (this.statistics = null);
    },
    clearCharts() {
      this.chartOrders != null
        ? this.chartOrders.destroy()
        : (this.chartOrders = null);
      this.chartAmount != null
        ? this.chartAmount.destroy()
        : (this.chartAmount = null);
      this.$forceUpdate();
    },
    handleSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          console.log(this.dates);
          this.$emit("loading", true);
          this.$http
            .get(
              `${this.url}?dateStart=${this.dates.dateStart}&dateEnd=${this.dates.dateEnd}&id=${this.dates.id}`
            )
            .then(resp => {
              console.log(resp.data);
              this.statistics = resp.data;
              if (resp.data && resp.data.discountCodeOrderStatusStatistics) {
                Object.keys(resp.data.discountCodeOrderStatusStatistics).map(
                  key => {
                    this.countedOrders +=
                      resp.data.discountCodeOrderStatusStatistics[
                        key
                      ].counted_orders;
                    return resp.data.discountCodeOrderStatusStatistics[key]
                      .counted_orders;
                  }
                );
                this.chartOrders = this.createChart(
                  "discount-code-group-statistics-orders",
                  resp.data.discountCodeOrderStatusStatistics,
                  "counted_orders"
                );
              }
              if (resp.data && resp.data.discountCodeOrderStatusStatistics) {
                Object.keys(resp.data.discountCodeOrderStatusStatistics).map(
                  key => {
                    this.countedAmount +=
                      resp.data.discountCodeOrderStatusStatistics[
                        key
                      ].total_amount;
                    return resp.data.discountCodeOrderStatusStatistics[key]
                      .total_amount;
                  }
                );
                this.chartAmount = this.createChart(
                  "discount-code-group-statistics-amount",
                  resp.data.discountCodeOrderStatusStatistics,
                  "total_amount"
                );
              }
              this.$emit("loading", false);
            })
            .catch(err => {
              this.$toastr.error(err);
              console.log(err);
              this.$emit("loading", false);
            });
        }
      });
    },
    createChart(chartId, data, key) {
      const labels = Object.keys(data).map(value => data[value].status);
      const datasets = Object.keys(data).map(value => data[value][key]);
      // console.log(`chartId ${chartId}; ${document.getElementById(chartId)}`);
      const myChart = new Chart(document.getElementById(chartId), {
        type: "doughnut",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Darmowa dostawa statystyka",
              data: datasets,
              backgroundColor: [
                "rgb(150, 153, 203)",
                "rgb(254, 200, 216)",
                "rgb(255, 223, 211)",
                "rgb(182, 211, 235)",
                "rgb(210, 145, 188)",
                "rgb(224, 187, 228)",
                "rgb(149, 125, 173)"
              ]
            }
          ]
        }
      });
      return myChart;
    }
  }
};
</script>
style>

<style>
.product-label {
  display: flex;
  justify-content: space-between;
  align-dates: center;
}
.v-select input::placeholder {
  color: rgb(167, 167, 167) !important;
}
</style>
